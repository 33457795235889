const config = {
  GOOGLE: {
    API_KEY: 'AIzaSyCpl497dKbKN-piJBJJ5zOf3sCPk7CKuJg'
  },
  SOCKET_URL: 'http://localhost:5000',
  BASE_URL: 'http://localhost:5000',
  HOST: 'http://localhost:5000'
}

export default config
