const config = {
  GOOGLE: {
    API_KEY: 'AIzaSyCpl497dKbKN-piJBJJ5zOf3sCPk7CKuJg'
  },
  SOCKET_URL: 'https://www.trademebooks.com',
  BASE_URL: 'https://www.trademebooks.com',
  HOST: 'https://www.trademebooks.com'
}

export default config
